<template>
  <div id="mianBigBox" class="clear"  :class="isActiveHead==true ? 'hideSidebar' : 'openSidebar'">
    <div class="left_nav">
      <el-container>
        <el-aside style="width: 202px">
          <el-menu
            :collapse-transition="false"
            :unique-opened="true"
            :router="true"
            :default-active="$route.path"
            class="el-menu-vertical-demo"
          >
            <el-submenu v-auth="'ACCOUNT:ORG'" index="1">
              <template slot="title">
                <span slot="title">机构管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:ORG:ORGS'"
                index="/user/organization_jggl"
                >机构列表</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:MCH'" index="2">
              <template slot="title">
                <span slot="title">商户管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:MCH:MCHS'"
                index="/user/businessList_shlb"
                >商户列表</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:AGENT'" index="3">
              <template slot="title">
                <span slot="title">代理商管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:AGENT:AGENTS'"
                index="/user/agentlist_dlslb"
                >代理商列表</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:ALLOC'" index="4">
              <template slot="title">
                <span slot="title">分账商户管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:ALLOC:MERCHNATS'"
                index="/user/subaccountlist_shlb"
                >分账商户列表</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ELECTRLED:MCHCOM'" index="5">
              <template slot="title">
                <span slot="title">电子账簿商户管理</span>
              </template>
              <el-menu-item
                v-auth="'ELECTRLED:MCHCOM:MCHLIST'"
                index="/user_yh/ledger/index"
                >电子账簿商户列表</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:STORE'" index="6">
              <template slot="title">
                <span slot="title">店铺管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:STORE:STORES'"
                index="/user/shoplist_mdlb"
                >店铺列表</el-menu-item
              >
              <el-menu-item
                v-auth="'ACCOUNT:STORE:FIND'"
                index="/user/shop_dpcx"
                >店铺查询</el-menu-item
              >
              <el-menu-item 
                v-auth="'ACCOUNT:STORE:JJDL'"
                index="/user/Into_jjdl">进件队列</el-menu-item>
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:WXAPP'" index="7">
              <template slot="title">
                <span slot="title">小程序管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:WXAPP:THIRDS'"
                index="/user/third_party"
                >第三方列表</el-menu-item
              >
              <el-menu-item
                v-auth="'ACCOUNT:WXAPP:APPS'"
                index="/user/applet_list"
                >小程序列表</el-menu-item
              >
               <el-menu-item
                v-auth="'ACCOUNT:TERMINAL:CiF'"
                index="/user/terminal_config"
                >终端小程序配置</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:IP'" index="8">
              <template slot="title">
                <span slot="title">终端IP管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:IP:SETTING'"
                index="/user/ip_setting"
                >终端IP配置</el-menu-item
              >
            </el-submenu>
            <el-submenu v-auth="'ACCOUNT:INTETT'" index="9">
              <template slot="title">
                <span slot="title">智能路由管理</span>
              </template>
              <el-menu-item
                v-auth="'ACCOUNT:INTETT:DEPLOY'"
                index="/user/intelligence/index"
                >智能路由配置</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </el-aside>
      </el-container>
    </div>
    <div class="right_con">
      <div class="right_con_nav">
        <el-tabs
          v-model="activeTab"
          closable
          @tab-click="clickTab"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            v-for="item in tabs"
            :key="item.fullPath"
            :label="item.title"
            :name="item.fullPath"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="clearBt" @click="clearTab">
          <i class="el-icon-delete"></i>
        </div>
      </div>
      <div class="right_con_con">
        <!-- <div class="title">{{$route.meta.title}}</div> -->
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeTab: "",
      tabs: [],
      isActiveHead:this.$store.state.isActiveHead,
    };
  },
  created() {
    this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
    this.activeTab = sessionStorage.getItem("active_tab");
    var localValue;
    this.bus.$on('isActiveHead', res => {
        localValue = res;//当bus触发时，这里生效，将sendNavText值修改了，但是，当点开该页面路由组件的时候，data函数初始化了sendNavText值
        this.$store.state.isActiveHead = res; //(1)将该值存在store中
        this.isActiveHead = res;
    })
  },
  watch: {
    $route: {
      handler: function () {
        this.tabs = JSON.parse(sessionStorage.getItem("tabs"));
        this.activeTab = sessionStorage.getItem("active_tab");
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    clickTab(tab) {
      this.$router.push(tab.name);
    },
    removeTab(targetName) {
      if (this.tabs.length == 1) {
        return;
      }
      this.tabs.forEach((v, i) => {
        if (v.fullPath === targetName) {
          if (targetName === this.activeTab) {
            if (i !== 0) {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[i - 1].fullPath);
              this.$router.push(this.tabs[i - 1].fullPath);
            } else {
              this.tabs.splice(i, 1);
              sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
              this.$router.push(this.tabs[0].fullPath);
            }
          } else {
            this.tabs.splice(i, 1);
          }
          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          return;
        }
      });
    },
    // 清除tab
    clearTab() {
      this.$confirm("确认清空吗?", "清空", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("	this.tabs", this.$route.meta.tab);
          // return;

          this.tabs = [
            {
              title: this.$route.meta.tab,
              fullPath: this.$route.fullPath,
            },
          ];

          sessionStorage.setItem("tabs", JSON.stringify(this.tabs));
          sessionStorage.setItem("active_tab", this.tabs[0].fullPath);
          this.$message({
            type: "success",
            message: "清空成功",
            duration: 2000,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空",
          });
        });
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/index.css';
</style>